import React from "react";
import "./Skills.css";
import Skill from "./Skill";
import Interests from "./Interests";
function Skills() {
  return (
    <>
      <div className="skills">
        <h2 className="h2">Compétences</h2>
        <Skill title="Électronique numérique" rating="5" />
        <Skill title="Électronique analogique" rating="5" />
        <Skill title="C,Python, BCL, Script Shell" rating="3" />
        <Skill title="HTML-Visual Studio" rating="3" />
        <Skill title="Xilinx-Zyncq-7000" rating="5" />
        <Skill title="GPIB, VXI, COMPACT PCI, JTAG" rating="4" />
        <Skill title="Assembleur ,VHDL, limm," rating="2" />
        <Skill title="Orcad Schématique" rating="4" />
        <Skill title="KiCad, MATLAB," rating="3" />
        <Skill title="Pack Office" rating="3" />
        <Skill title="Pack Mac" rating="3" />
        <Skill title="CAN, FMS, Ethernet" rating="3" />
        <Skill title="Bluetooth, GPIB" rating="3" />
      </div>
      <div className="skills">
        <h2 className="h2">Langues</h2>
        <Skill title="Anglais Professionnel Master" rating="3" />
      </div>
      <Interests />
    </>
  );
}

export default Skills;
