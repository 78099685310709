import React from "react";
import "./User.css";
// eslint-disable-next-line no-unused-vars
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
// eslint-disable-next-line no-unused-vars
import EventIcon from "@material-ui/icons/Event";
// eslint-disable-next-line no-unused-vars
import LocationOnIcon from "@material-ui/icons/LocationOn";

function User() {
  return (
    <div className="user">
      <img src="./images/avatar.jpeg" alt="" />
      <h1 className="user__name">Ibrahima BA</h1>
      <p className="user__profession">Bureau d'étude en électronique</p>
      <div className="user__infos">
        <p className="user__info">
          <PhoneIcon /> <a href="tel:+33370368414">0670368414</a>
        </p>
        <p className="user__info">
          <MailIcon /> <a href="mailto:contact@ibrahimaba.fr">contact@ibrahimaba.fr</a>
        </p>
      </div>
    </div>
  )
}

export default User;
