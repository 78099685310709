import React from "react";

function Profil() {
  return (
    <div className="profil mb5">
      <h2 className="h2">Profil</h2>
      <p>
      Je suis un homme de la technique, et de nombreuses victoires à mon actif. 
      Mon expertise dans les secteurs du spatial, de l'aéronautique et du transport a permis de faire avancer les entreprises où j'ai travaillé. 
      Je privilégie l'action, entouré de collègues passionnés, pour atteindre l'excellence.
      </p>
    </div>
  );
}

export default Profil;
