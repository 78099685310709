export const dataExperiences = [
  {
    id: 1,
    title: "Bureau d'étude électronique",
    date: "11/2023 - Aujourd'hui",
    location: "2EVT à Blagnac",
    texte:
      "Réalisation de carte et banc de test avec des systèmes électroniques",
    missions: [
      {
        id: 1,
        title:
          "Mise en oeuvre de carte, banc de test, de tiroir et de système électronique. Preuve de concept (POC).Schématique et routage avec KiCadAssemblage, intégration et validation (respect du cycle en V)",
      },
      {
        id: 2,
        title:
          "Résultat final : Réalisations techniques.",
      },
    ],
  },
  {
    id: 2,
    title: "Électronicien en Système et VOIP à Navocap",
    date: "12/2021 - 10/2023",
    location: "NAVOCAP à Blagnac",
    texte:
      "Mise en oeuvre de système électronique permettant de faire la VoIP dans le secteur du transport.",
    missions: [
      {
        id: 1,
        title:
          "Validation de carte et boitier électronique pour le ferroviaire, mise en oeuvre d'une table de vérité pour la VOIP avec l'ensemble des cas fonctionnels, Multicast - Unicast sur équipement VOIP. Preuve de concept sur un routeur NETMODULE NB800 pour le fonctionnement du MQTT FMS-CAN. Mise en place d un banc de test pour un boîtier VOIP. ",
      },
      {
        id: 2,
        title:
          "Résultat final : Mise en production des réalisations techniques.",
      },
    ],
  },
  {
    id: 3,
    title: "Assistant-ingénieur en Électronique",
    date: "03/2020 - 08/2020",
    location: "IRAP à Toulouse",
    texte:
      "Réalisation d’instrument d’observation pour l’Observatoire du Midi-Pyrénées, projets : SPIP.",
    missions: [
      {
        id: 1,
        title:
          "Actualisation de la schématique avec le logiciel Orcad, réalisation synoptique d’intégration matérielle de la baie spectro, préparation du dossier et suivi de la fabrication câblage, participation à la rédaction des procédures de tests, réalisation des tests unitaires, à chaque réception des sous- ensembles électroniques, intégration de l’électronique de contrôle commande du spectrographe.",
      },
      {
        id: 2,
        title:
          "Résultat final : partie technique en partie terminée, malgré les contraintes Covid 19.",
      },
    ],
  },
  {
    id: 4,
    title: "Assistant-ingénieur en Électronique",
    date: "06/2018 - 06/2019",
    location: "STEEL Électronique à Martres-Tolosane ",
    texte:
      "Recette de calculateurs satellites, projet : Angels, NINANO, AURIGA.",
    missions: [
      {
        id: 1,
        title:
          "Test, recette, analyse et débugge d’un calculateur de type ANGELS dans l’environnement du spatial à l’aide de moyens de test (Multimètre, oscilloscope, analyseur de réseau ect).",
      },
      {
        id: 2,
        title:
          "Environnement technique : Linux, FPGA Xilinx - Zyncq-7000, écriture de script limm, norme électronique, gestion des configurations matérielles et systèmes.",
      },
      {
        id: 3,
        title: "Résultat final : livraison client (Nexeya).",
      },
    ],
  },
  {
    id: 5,
    title: "Réparation de Terminaux Électronique",
    date: "02/2016 - 05/2018",
    location: "CLS Toulouse à la Direction Technique SSIS/IS à Ramonville",
    texte: "Réparation de balise de géolocalisation, projet : Triton,LEO, ect.",
    missions: [
      {
        id: 1,
        title:
          "Réparation et préparation de terminaux satellites, participation à la préparation des courses Vendée Globe et Transat New York-Vendée, et gestion des pièces détachées du stock.",
      },
      {
        id: 2,
        title:
          "Kimo à Montpon-Ménestérol – mesure physique et amélioration des procédures de test.",
      },
    ],
  },
  {
    id: 6,
    title: "Technicien en Système Électronique",
    date: "09/2008 - 03/2015",
    location: " Expleo anciennement : Assystem  à Toulouse ",
    texte: "Technicien en Système Électronique.",
    missions: [
      {
        id: 1,
        title:
          "2014-2015 : Rolls-Royce : instrumentation FTY dans les moteurs d’avion.",
      },
      {
        id: 2,
        title:
          "2013 : Airbus (EV) en 2013 logistique et mesure physique des accéléromètres.",
      },
      {
        id: 3,
        title:
          "2009-2013 : CNES (DCT/SI/CD) : Recette électronique de composants de types chaîne vidéo , métrologie sur les sources d’alimentations laboratoire.",
      },
    ],
  },
];
export default dataExperiences;
