const dataFormations = [
  {
    id: 1,
    title: "Formation Ingénieur Electronique à l'IPST CNAM de Toulouse",
    date: "01/10/2017 - Aujourd'hui",
    location: "Toulouse",
  },
  {
    id: 2,
    title: "BTS Electronique",
    date: "01/09/2008 - 01/07/2010",
    location: "Toulouse",
  },
  {
    id: 3,
    title: "Baccalauréat Profesionnel en Maintenance Audio Visuel Electronique",
    date: "04/09/2000 - 01/07/2002",
    location: "Lycée de Jean Monet à Foulayronnes",
  },
];
export default dataFormations;
